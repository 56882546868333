import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

function Navbar() {
  const [ isNavOpen, setIsNavOpen ] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  }

  return (
    <nav className='navbar'>
      <div className="navbar-menu">
        <Link to='/' className='navbar-brand'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-home"><path className="primary" d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/><path className="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"/></svg>
        </Link>
        <div className={`navbar-menu-items ${isNavOpen ? 'is-active' : ''}`}>
          <NavLink to='/listings'>Listings</NavLink>
          {/* <NavLink to='/buying-process'>Buying Process</NavLink> */}
          {/* <NavLink to='/faq'>FAQ's</NavLink> */}
          <NavLink to='/about'>About Us</NavLink>
          <div className="navbar-ctas">
            <Link className='navbar-ctas-contact' to='/contact'>Contact us</Link>
          </div>
        </div>
        <button className='navbar-burger-icon' onClick={toggleNav}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/></svg>
        </button>
      </div>
    </nav>
  )
}

export default Navbar;
