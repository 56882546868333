import React from 'react';

import Hero from './hero/Hero';
import Highlights from './highlights/Highlights';
import Testimonials from './testimonials/Testimonials';
import EmailCapture from './email-capture/EmailCapture';

function Home() {
  return (
    <main className='Home'>
      <Hero />
      <Highlights />
      <Testimonials />
      {/* <EmailCapture /> */}
    </main>
  )
}

export default Home;
