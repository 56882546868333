import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <section className='hero'>
      <div className="hero-text">
        <h2>Away Land Co</h2>
        <h1>Invest in a brighter future with Raw Land</h1>
        <p>Land investing made easy, with straightforward terms, cash-back guarantees, and the lowest prices.</p>
        <div className="hero-btns">
          <Link to='/listings' className='hero-btns-listings'>View Listings</Link>
          {/* <Link to='/buying-process' className='hero-btns-learn'>Learn How It Works</Link> */}
        </div>
      </div>
    </section>
  )
}

export default Hero;
