import React, { useRef, useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../contexts/firebase.context';

import ListingCard from './listingCard/ListingCard';

function Listings() {
  const firebase = useContext(FirebaseContext);
  const countySelectionRef = useRef(null);
  const [ currentCounty, setCurrentCounty ] = useState("All");
  const [ listings, setListings ] = useState([]);

  useEffect(() => {
    const fetchListingsFromFirestore = async() => {
      try {
        const fetchedListings = [];

        const db = firebase.firestore();
        const collectionRef = db.collection('listings');
        const querySnapshot = await collectionRef.get();

        querySnapshot.forEach((doc => {
          fetchedListings.push(doc.data());
        }));

        setListings(fetchedListings);
      }
      catch (err) {
        console.error(err);
      }
    };

    fetchListingsFromFirestore();
  }, [ firebase ]);
 
  const filterListings = (lstngs) => {
    const filteredListings = lstngs.filter((lstng) => {
      if (lstng.sold === true) return false;
      else if (lstng.county !== currentCounty && currentCounty !== "All") return false;
      return true;
    });
    return filteredListings;
  };

  const handleSelect = () => {
    setCurrentCounty(countySelectionRef.current.value);
  };

  if (!listings) {
    return (<h2>Loading...</h2>);
  }

  return (
    <div className='listings'>
      <h2>Browse our growing list of active listings</h2>

      <div className="listings-sort-by">
        <h3>Filter by county:</h3>
        <select name="filter-county" id="filter-county" ref={countySelectionRef} onChange={handleSelect}>
          <option value="All">Show All Counties</option>
          <option value="Fremont">Fremont County</option>
          <option value="Huerfano">Huerfano County</option>
          <option value="Costilla">Costilla County</option>
        </select>
      </div>

      <div className="listings-gallery">
        {listings && filterListings(listings).map(listing => (
          <ListingCard key={listing.id} listing={listing} />
        ))}
      </div>
    </div>
  )
}

export default Listings;
