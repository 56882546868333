import React, { useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { AuthedUserContext } from '../../../contexts/authedUser.context';
import { FirebaseContext } from '../../../contexts/firebase.context';

function Login(props) {
  const firebase = useContext(FirebaseContext);
  const { authedUser, setAuthedUser } = useContext(AuthedUserContext);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await firebase.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      setAuthedUser(response.user);
      console.log(response);
      props.history.push('/admin/dashboard')
    }
    catch(err) { console.log(err) }
  };

  if (authedUser) return props.history.push('/admin/dashboard');

  return (
    <main className='Login'>
      <h2>Login to access this page.</h2>

      <form onSubmit={handleLogin} className='Login-form'>
        <input type="email" name="email" id="email" ref={emailRef} />
        <input type="password" name="password" id="password" ref={passwordRef} />
        <button>Log in</button>
      </form>
    </main>
  )
}

export default withRouter(Login);
