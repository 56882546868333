import React from 'react';
import { Link } from 'react-router-dom';

function ListingCard(props) {
  const { listing } = props;

  return (
    <Link to={`/listings/${listing.id}`} className='listing-card'>
      <div className="listing-card-image-gallery">
        <img className='listing-card-image' src={listing.images[0].thumbnail} alt="Listing Thumbnail"/>
      </div>
      <main className="listing-card-content">
        <h4 className="listing-card-title">
          {listing.cashPrice}   
          <span>&nbsp;($120/mo)</span>
        </h4>
        <p className="listing-card-subtitle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-location-pin"><g><path className="secondary" d="M12 1v6a3 3 0 0 0 0 6v9.31a1 1 0 0 1-.7-.29l-5.66-5.66A9 9 0 0 1 12 1z"/><path className="primary" d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"/></g></svg>
          {listing.size}
        </p>
<p className="listing-card-description">{listing.state} | {listing.county} {listing.county.match(/county/i) ? '' : 'County'}</p>
      </main>
    </Link>
  )
}

export default ListingCard;
