import React, { useRef, useContext } from 'react';
import { FirebaseContext } from '../../contexts/firebase.context';
import { AuthedUserContext } from '../../contexts/authedUser.context';

function ComingSoon() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const firebase = useContext(FirebaseContext);
  const { setAuthedUser } = useContext(AuthedUserContext);

  const handleLogin = async(event) => {
    event.preventDefault();
    try {
      const res = await firebase.auth().signInWithEmailAndPassword(
        emailRef.current.value, passwordRef.current.value
      );
      setAuthedUser(res.user);
    }
    catch (err) { console.log(err) }
  };

  return (
    <main className='coming-soon'>
      <div className="coming-soon-body">
        <h1>You must log in to access this site:</h1>
        <div className="coming-soon-form">
          <input type="email" ref={emailRef} placeholder="Email" />
          <input type="password" ref={passwordRef} placeholder="Password" />
          <input type="submit" value="Log in" onClick={handleLogin}/>
        </div>
      </div>
    </main>
  )
}

export default ComingSoon;
