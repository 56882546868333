import React from 'react';

function Highlights() {
  return (
    <section className='highlights'>
      <h2>Away Land Co helps you find the perfect parcel for your needs</h2>
      <div className="highlights-panels">
        <div className="panel">
          <div className="panel-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-security-check"><path className="primary" d="M4 4l8-2 8 2v9.06a8 8 0 0 1-4.42 7.15L12 22l-3.58-1.79A8 8 0 0 1 4 13.06V4z"/><path className="secondary" d="M10.5 12.59l4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42l1.3 1.3z"/></svg>
          </div>
          <div className="panel-text">
            <h3>Love your listing or get 100% of your money back. Guaranteed.</h3>
            <p>If you don't absolutely love your purchase we'll refund your money - No questions asked.</p>
          </div>
          <div className="panel-chevron">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-down"><path className="primary" fillRule="evenodd" d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/></svg>
          </div>
        </div>
        <div className="panel">
          <div className="panel-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-light"><path className="primary" d="M5 8a7 7 0 1 1 10.62 6l-.64 3.2a1 1 0 0 1-.98.8h-4a1 1 0 0 1-.98-.8L8.38 14A7 7 0 0 1 5 8zm12 0a5 5 0 0 0-5-5 1 1 0 0 0 0 2 3 3 0 0 1 3 3 1 1 0 0 0 2 0z"/><path className="secondary" d="M15 21a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2 1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"/></svg>
          </div>
          <div className="panel-text">
            <h3>Online support to answer all your questions & concerns</h3>
            <p>We make buying easy by explaining everything in great detail. Still confused? We're always availabe to help.</p>
          </div>
          <div className="panel-chevron">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-down"><path className="primary" fillRule="evenodd" d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/></svg>
          </div>
        </div>
        <div className="panel">
          <div className="panel-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-currency-dollar"><circle cx="12" cy="12" r="10" className="primary"/><path className="secondary" d="M13 7.12v3.96c2.25.36 4 1.93 4 3.92 0 2-1.75 3.56-4 3.92V19a1 1 0 0 1-2 0v-.08a4.82 4.82 0 0 1-3.67-2.49 1 1 0 0 1 1.8-.85c.29.6.98 1.09 1.87 1.3v-3.96C8.75 12.56 7 11 7 9c0-2 1.75-3.56 4-3.92V5a1 1 0 0 1 2 0v.08a4.81 4.81 0 0 1 3.68 2.5 1 1 0 0 1-1.81.85c-.28-.6-.98-1.1-1.87-1.31zm-2 3.76V7.12C9.81 7.4 9 8.18 9 9c0 .82.81 1.6 2 1.88zm2 2.24v3.76c1.19-.28 2-1.06 2-1.88 0-.82-.81-1.6-2-1.88z"/></svg>
          </div>
          <div className="panel-text">
            <h3>Flexible payment options and the lowest prices on land</h3>
            <p>We go to great lengths to get you the best prices and ensure you can pay when and how you want.</p>
          </div>
          <div className="panel-chevron">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-down"><path className="primary" fillRule="evenodd" d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/></svg>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Highlights;
