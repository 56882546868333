import React from 'react';
import { Link } from 'react-router-dom';

import imageSrc from '../../assets/images/logo-black-transparent.png';

function Footer() {
  return (
    <footer className='Footer'>
      <div className="Footer-container">
        <div className="Footer-left">
          <img src={imageSrc} alt=""/>
        </div>

        <div className="Footer-mid">
          <h3>Away Land Co</h3>
          <p><strong>Phone:</strong> (469) 500-6305</p>
          <p><strong>Email:</strong> hello@awaylandco.com</p>
        </div>

        <div className="Footer-right">
          <h4>Navigation</h4>
          <Link to='/'>Home</Link>
          <Link to='/listings'>Listings</Link>
          <Link to='/about'>About</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
